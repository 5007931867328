import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Row,
  Col,
  InputGroup,
  InputGroupText,
  Spinner,
} from "reactstrap";
import TextField from "@mui/material/TextField";
import Popper from '@mui/material/Popper';

import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import moment from "moment";
import {
  deCryptFun,
  enCryptFun,
  getEmail,
  getRole,
  getSorting,
  getTeamID,
  stableSort,
  getUserId,
  getPosition,
} from "../comman/function";
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import { authHeader, getAlert, getAlertToast } from "../comman/mainfunction";
import API from "../constants/API";
import {
  EXTRACTION_FORM_URL,
  GET_FORMAT_SETTING_URL,
  GET_SUPPLIER_LIST_URL,
  GET_TRAINED_DATASET_URL,
  TEAM_MEMBER_ASSIGN,
  ADD_SUPPLIER_REQUEST_URL,
} from "../comman/url";
import Swal from "sweetalert2";
import { valuesIn } from "lodash";
import { detect } from "underscore";
import {
  LETTERS,
  LETTERS_NUMBERS_SYMBOLS,
  NUMBERS_DOT_REGEX,
  TIME_MMDDYYYY_REGEX,
  VALID_DATE_DDMMYYYY_REGEX,
  VALID_DATE_MMDDYYYY_REGEX,
  NUMBER_TWO_DECIMAL
} from "../comman/constants";
import { FaSyncAlt } from "react-icons/fa";
import MaskedInput from "react-text-mask";
import { Box, Paper, styled } from "@mui/material";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../comman/helperfunction";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_FORMDATA } from "../redux/action/ActionType";
import Currency from "../../src/commonCurrency.json"
import {getTeamMembers} from "../comman/requests";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f0f1f5",
    color: theme.palette.common.black,
    lineHeight: "1rem",
    padding: "16px 12px !important",
    FontWeight: "600",
    width: "auto",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "14px !important",
    fontWeight: 400,
    textAlign: "left",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const PopupBody = styled('div')(
  ({ theme }) => `
  position: relative;
  width: max-content;
  padding: 12px 16px;
  margin: 8px;
  border-radius: 8px;
  border: 1px solid #111;
  background-color: #fff;
  box-shadow: ${
    theme.palette.mode === 'dark'
      ? `0px 4px 8px rgb(0 0 0 / 0.7)`
      : `0px 4px 8px rgb(0 0 0 / 0.1)`
  };
  z-index: 10000;
`,
);

function createData(name, calories, fat) {
  return { name, calories, fat };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0),
  createData("Ice cream sandwich", 237, 9.0),
  createData("Eclair", 262, 16.0),
];
//table end
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function InvoiceFormNewExtract(props) {
  const {
    invoiceID = "",
    // isEdit,
    errors,
    touched,
    requiredFieldList,
    handleChange,
    values,
    defaultInvoiceType,
    setFormValues,
    setValues,
    setErrors,
    keyChangeFlag,
    setKeyFlag,
    keyData,
    setKeyData,
    fieldArray,
    setFieldArray,
    detectedData,
    setDetectedData,
    dateShow,
    loadingdetect,
    dynamicVal,
    setDynamicVal,
    formValues,
    newSupplierData,
    handleBlur,
    setDueDateError,
    setInvoiceDateError,
    setDiscountDateError,
    invoiceDateError,
    discountDateError,
    dueDateError,
    unlockInvoice,
    setisPoLineChange,
  } = props;
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("1");
  const [commonCurrency,setCommanCurrency] = useState(Object.keys(Currency))

  // const formValues = useSelector((st) => st.Invoiceformdata);
  const isEdit = useSelector((s) => s.EditReducer);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  let {
    invoiceNumber,
    invoiceDate,
    operatingUnit,
    invoiceType,
    supplierName,
    supplierNumber,
    supplierType,
    supplierSite,
    supplierId,
    supplierAddress,
    city,
    state,
    supplierAddress2,
    invoiceCurrency,
    invoiceAmount,
    dueAmount,
    taxTotal,
    dueDate,
    dueDateYYYMMDD,
    totalAmount = 0,
    orderNumber,
    name,
    senderEmail,
    phoneNumber,
    status,
    source,
    country,
    taxNumber,
    documentType,
    bankAccount,
    sortCode,
    swift,
    iban,
    paidAmount,
    // invoiceDescription,
    paymentTerms,
    supplierEmail,
    invoiceNumberTR,
    invoiceAmountTR,
    dueAmountTR,
    taxNumberTR,
    bankAccountTR,
    sortCodeTR,
    paidAmountTR,
    swiftTR,
    ibanTR,
    invoiceDateTR,
    taxTotalTR,
    totalAmountTR,
    invoiceCurrencyTR,
    dueDateTR,
    supplierAddressTR,
    supplierSiteTR,
    invoiceDescriptionTR,
    senderEmailTR,
    orderNumberTR,
    nameTR,
    supplierNameTR,
    phoneNumberTR,
    documentTypeTR,
    postalCode,
    invoiceId,
    mailStop,
    discountDate,
    descriptionHeader
  } = values;
  // const handleChange = (e) => {
  //   const { value, name } = e.target;
  //   dispatch({
  //     type: UPDATE_FORMDATA,
  //     payload: { ...formValues, [name]: value },
  //   });
  // };
  //const [dynamicVal, setDynamicVal] = useState([]);
  useEffect(() => {
    fieldArray.map((rec, i) => {
      const { targetVariable, fieldName } = rec;
      return detectedData && detectedData[targetVariable]
        ? (rec.fieldName = detectedData[targetVariable].label)
        : "";
    });

    setSelectedOption({
      ...selectedOption,
      ["Invoice Date"]: detectedData.invoiceDate?.label || "",
      ["Invoice Number"]: detectedData.invoiceNumber?.label || "",
      ["Invoice Amount"]: detectedData.invoiceAmount?.label || "",
      ["Due Amount"]: detectedData.dueAmount?.label || "",
      ["Purchase Order Number"]: detectedData.orderNumber?.label || "",
      ["Tax Total"]: detectedData.taxTotal?.label || "",
      ["Due Date"]: detectedData.dueDate?.label || "",
      ["Mail Stop"]: detectedData.mailStop?.label || "",
      ["Discount Date"]: detectedData.discountDate?.label || "",
      ["Description Header"]: detectedData.descriptionHeader?.label || "",
    });
  }, [detectedData]);

  const { enqueueSnackbar } = useSnackbar();
  const [startDate, setStartDate] = useState("");
  const [duedate, setdueDate] = useState("");
  const [discountdate, setdiscountDate] = useState("");
  const [type, setType] = useState("MM/dd/yyyy");
  const [rowValue, setRowValue] = useState("");
  const [rawsec, setRawSec] = useState([]);
  const [rawsec1, setRawSec1] = useState([]);
  const [f, setF] = useState();
  const [selectedOption, setSelectedOption] = useState({});
  const [invoiceKeyPress, setInvoiceKeyPress] = useState("");
  const [dueKeyPress, setDueKeyPress] = useState("");
  const [invalidInvoiceDate, setInvalidInvoiceDate] = useState("");
  const [invalidDueDate, setInvalidDueDate] = useState("");
  const [invalidDiscountDate, setInvalidDiscountDate] = useState("");
  const [supplierList, setSupplierList] = useState([]);
  const TeamReducer = useSelector((s) => s.TeamReducer);
  const [value, setValue] = React.useState(null);
  const [isBackButtonClicked, setBackbuttonPress] = useState(false);
  const [editvalues,setEditValues] = useState(false)
  const RowHandlerChk = (e, node) => {

    let temp = rawsec;
    let temp1 = keyData;
    let temp2 = fieldArray;

    let fafind = temp2.find(
      (element) => element.targetVariable == node?.props?.exaval
    );

    if (fafind) {
      temp2.find(
        (element) => element.targetVariable == node?.props?.exaval
      ).fieldName = e.target.value;
      
    }

    setFieldArray(temp2);

    let fe = temp.find((element) => element.name == node?.props?.labname);

    let fe1 = temp1.find(
      (element) => element.targetVariable == node?.props?.exaval
    );
    let dylab = node?.props?.exaval;

    let dv =
      e.target.value != ""
        ? dynamicVal.find((ele) => ele.label == e.target.value).value
        : "";

    if (dylab == "invoiceDate" && e.target.value != "")  {
      if (VALID_DATE_MMDDYYYY_REGEX.test(dv)) {
        setInvalidInvoiceDate("validInvoice");
        setStartDate(new Date(dv));
        dispatch({
          type: UPDATE_FORMDATA,
          payload: { ...formValues, [node?.props?.exaval]: dv },
        });

        setFormValues({ ...values, [node?.props?.exaval]: dv });
        setValues((val) => ({ ...val, [node?.props?.exaval]: dv }));
      } else {
        setInvalidInvoiceDate("invalidInvoice");
        setStartDate("");
        dispatch({
          type: UPDATE_FORMDATA,
          payload: { ...formValues, [node?.props?.exaval]: "" },
        });
        setFormValues({ ...values, [node?.props?.exaval]: "" });
        setValues((val) => ({ ...val, [node?.props?.exaval]: "" }));
        // Swal.fire(getAlertToast("error", "Invalid Date5"));
        CustomMessage("Invalid Date", "error", enqueueSnackbar); //call toster

        // setErrors({invoiceDate:"Invalid Date"})
      }
    } else if (dylab == "dueDate" && e.target.value != "") {
      if (VALID_DATE_MMDDYYYY_REGEX.test(dv)) {
        setInvalidDueDate("validDue");
        setdueDate(new Date(dv));
        dispatch({
          type: UPDATE_FORMDATA,
          payload: { ...formValues, [node?.props?.exaval]: dv },
        });
        setFormValues({ ...values, [node?.props?.exaval]: dv });
        setValues((val) => ({ ...val, [node?.props?.exaval]: dv }));
      } else {
        setInvalidDueDate("invalidDue");
        // Swal.fire(getAlertToast("error", "Invalid Date"));
        CustomMessage("Invalid Date", "error", enqueueSnackbar); //call toster

        setdueDate("");
        dispatch({
          type: UPDATE_FORMDATA,
          payload: { ...formValues, [node?.props?.exaval]: "" },
        });
        setFormValues({ ...values, [node?.props?.exaval]: "" });
        setValues((val) => ({ ...val, [node?.props?.exaval]: "" }));
      }
    } else if (dylab == "discountDate" && e.target.value != "") {
      if (VALID_DATE_MMDDYYYY_REGEX.test(dv)) {
        setInvalidDiscountDate("validDiscount");
        setdiscountDate(new Date(dv));
        dispatch({
          type: UPDATE_FORMDATA,
          payload: { ...formValues, [node?.props?.exaval]: dv },
        });
        setFormValues({ ...values, [node?.props?.exaval]: dv });
        setValues((val) => ({ ...val, [node?.props?.exaval]: dv }));
      } else {
        setInvalidDiscountDate("invalidDiscount");
        // Swal.fire(getAlertToast("error", "Invalid Date"));
        CustomMessage("Invalid Date", "error", enqueueSnackbar); //call toster

        setdiscountDate("");
        dispatch({
          type: UPDATE_FORMDATA,
          payload: { ...formValues, [node?.props?.exaval]: "" },
        });
        setFormValues({ ...values, [node?.props?.exaval]: "" });
        setValues((val) => ({ ...val, [node?.props?.exaval]: "" }));
      }
    } else if (dylab != "dueDate" || dylab != "invoiceDate" || dylab != "discountDate") {
      dispatch({
        type: UPDATE_FORMDATA,
        payload: { ...formValues, [node?.props?.exaval]: dv },
      });
      setFormValues({ ...values, [node?.props?.exaval]: dv });
      setValues((val) => ({ ...val, [node?.props?.exaval]: dv }));
    }
    //rawsec
    if (fe) {
      temp.find((element) => element.name == node?.props?.labname).value =
        e.target.value;
    } else {
      temp.push({
        name: node?.props?.labname,
        value: e.target.value,
      });
    }
    setRawSec(temp);

    //keydata
    if (fe1) {
      temp1.find(
        (element) => element.targetVariable == node?.props?.exaval
      ).fieldName = e.target.value;
    } else {
      temp1.push({
        fieldName: e.target.value,
        targetVariable: node?.props?.exaval,
      });
    }
    setRawSec1(temp1);

    setKeyData(temp1);

    setKeyFlag(true);
    // let newArray = selectedOption
    // newArray.push({[e.target.id]: e.target.value})


    setSelectedOption({
      ...selectedOption,
      [node.props.filedid]: e.target.value || "",
    });
    // props.setTouched( "dueAmount", true)
    const validation = {
      dueAmount: "dueAmount",
    };
    if (validation[node.props.filedid]) {
      touched[validation[node.props.filedid]] = true;
    }
  };

  useEffect(() => {
    if (invalidDueDate === "invalidDue") {
      setErrors({ dueDate: "Allows Valid Date" });
    }
  }, [invalidDueDate]);

  useEffect(() => {
    if (invalidInvoiceDate === "invalidInvoice") {
      setErrors({ invoiceDate: "Allows Valid Date" });
    }
  }, [invalidInvoiceDate]);

  useEffect(() => {
    if (invalidDiscountDate === "invalidDiscount") {
      setErrors({ discountDate: "Allows Valid Date" });
    }
  }, [invalidDiscountDate]);

  // useEffect(()=>{
  //   if(name != "N/A" || name != undefined){
  //  newSupplierData()
  //   }
  // },[name])

  const alertUser = (e) => {
    const msg = "Please Update/Cancel the invoice";
    e.returnValue = msg;
    unlockInvoice();
    return e.returnValue;
  };
  const onBackButtonEvent = (e) => {
    e.preventDefault();

      window.removeEventListener('popstate', onBackButtonEvent);
    if (!isBackButtonClicked) {
      if (window.confirm("Do you want to go back")) {
        setBackbuttonPress(true)
        // props.history.go()
        window.history.replaceState(window.location="/invoice")
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setBackbuttonPress(false)
        setEditValues(true)
      }
    }
  }
  
  
  useEffect(() => {
    if (isEdit && !isBackButtonClicked) {
      window.onbeforeunload = alertUser;
      
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener('popstate', onBackButtonEvent);
     
      window.addEventListener("beforeunload",(ev) => {
        ev.preventDefault();
        return (ev.returnValue = "Are you sure you want to close?");
      });
      // window.addEventListener("beforeunload", alertUser);
    } 
    else {
      window.onbeforeunload = undefined;
    }
  }, [isEdit,editvalues]);
  const [selectFlag, setSelectFlag] = useState(true);
  const [selectFlaginvoice, setSelectFlaginvoice] = useState(true);

  const changedDate = (e) => {
    setStartDate(e);
    dispatch({
      type: UPDATE_FORMDATA,
      payload: { ...formValues, invoiceDate: e },
    });
    setValues((val) => ({ ...val, invoiceDate: e }));
  };

  const changedDatedue = (e) => {
    setdueDate(e);
    dispatch({
      type: UPDATE_FORMDATA,
      payload: { ...formValues, dueDateYYYMMDD: e, dueDate: e },
    });
    setValues((val) => ({ ...val, dueDateYYYMMDD: e, dueDate: e }));
  };

  const changedDatediscount = (e) => {
    setdiscountDate(e);
    dispatch({
      type: UPDATE_FORMDATA,
      payload: { ...formValues, discountDate: e },
    });
    setValues((val) => ({ ...val, discountDate: e }));
  };

  const getdateFormatSetting = async () => {
    const configget = {
      method: "GET",
      url: GET_FORMAT_SETTING_URL,
      params: {
        //teamId: getTeamID(),
        webString: enCryptFun(
          JSON.stringify({
            teamId: getTeamID(),
          })
        ),
        flutterString: "",
      },
      headers: authHeader(),
    };

    try {
      const response = await API(configget);
      //const { message, data } = response.data;
      let l = deCryptFun(response.data);
      const { message, data } = JSON.parse(l);
      if (message === "Success") {
        const { dateFormat = "MM/dd/yyyy" } = data;

        if (dateFormat == "") {
          setType("MM/dd/yyyy");
        } else {
          setType(dateFormat);
        }
      }
    } catch (error) {
      // let errorObj = Object.assign({}, error);
      // let { data } = errorObj.response;
      // Swal.fire("Error", data.message);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        // Swal.fire("Error", v.message);
        CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    }
  };

  const getEmailList = async () => {
    const config = {
      method: "GET",
      url: GET_SUPPLIER_LIST_URL,
      headers: authHeader(),
      params: {
        webString: enCryptFun(
          JSON.stringify({
            teamId: getRole() != "Supplier" ? getTeamID() : 0,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      // const { data } = response.data;
      let l = deCryptFun(response.data);
      const { data } = JSON.parse(l);
      setSupplierList(data);
    } catch (error) {
      console.error(error);
      if (error.response) {
        let { data } = error.response;

        let p = deCryptFun(data);
        let v = JSON.parse(p);
        //Swal.fire(getAlert("Error", v.message));
        // CustomMessage(v.message, "error", enqueueSnackbar); //call toster
      }
    }
  };

  useEffect(() => {
    getdateFormatSetting(getTeamID());
    if (getRole() != "Supplier") {
      getEmailList();
    }
  }, []);

  const startRef = useRef();
  const startRefinvoice = useRef();
  const onKeyDown = (e) => {
    setDueKeyPress("duekey");
    if (e.key === "Tab" || e.which === 9) {
      startRef.current.setOpen(false);
    }
  };
  const onKeyDowninvoice = (e) => {
    setInvoiceKeyPress("invoicekey");
    if (e.key === "Tab" || e.which === 9) {
      startRefinvoice.current.setOpen(false);
    }
  };
  const handleOnBlur = ({ target: { value } }) => {
    //   setDueKeyPress("dueRawKey")
    let format = "";
    if (type == "dd/MM/yyyy") {
      format = "DD/MM/yyyy";
    } else {
      format = "MM/DD/yyyy";
    }

    if (value) {
      if (
        format == "MM/dd/yyyy"
          ? VALID_DATE_MMDDYYYY_REGEX.test(value) == false || value.length != 10
          : VALID_DATE_DDMMYYYY_REGEX.test(value) == false || value.length != 10
      ) {

        // Swal.fire(getAlertToast("Error", "Invalid Date6"));
        CustomMessage("Invalid Date6", "error", enqueueSnackbar); //call toster

        setErrors({ dueDate: "Allows Valid Date" });
        setdueDate("");
        // setValues((val) => ({ ...val, dueDateYYYMMDD: "", dueDate: "" }));
      }
    }
    // else{

    // }
  };

  const handleChangeRaw = (value, event) => {
    setDueKeyPress("dueRawKey");
    let format = "";
    let month = "";
    let date = "";
    if (type == "dd/MM/yyyy") {
      format = "DD/MM/yyyy";
    } else {
      format = "MM/DD/yyyy";
    }

    if (value) {
      const final = new Date(moment(value, format));
      const unmask = value.replace(/-|_/gi, "").replace(/\//g, "");
      if (format == "MM/DD/yyyy") {
        month = unmask.substring(0, 2);
        date = unmask.substring(2, 4);
      } else {
        date = unmask.substring(0, 2);
        month = unmask.substring(2, 4);
      }

      if (
        (month.length == 2 &&
          !(month >= 0 && month <= 12) &&
          final == "Invalid Date") ||
        (date.length == 2 &&
          !(date >= 1 && date <= 31) &&
          final == "Invalid Date")
      ) {
        // Swal.fire(getAlertToast("Error", "Invalid Date"));
        CustomMessage("Invalid Date", "error", enqueueSnackbar); //call toster
        setErrors({ dueDate: "Allows Valid Date" });
        //value=''
        setdueDate("");
        dispatch({
          type: UPDATE_FORMDATA,
          payload: { ...formValues, dueDateYYYMMDD: "", dueDate: "" },
        });
        setValues((val) => ({ ...val, dueDateYYYMMDD: "", dueDate: "" }));

      }
      // else if (date.length == 2 && !(date >= 1 && date <= 31) && final == "Invalid Date") {
      //   Swal.fire(getAlertToast("Error", "Invalid Date"));
      //   setErrors({ dueDate: "Allows Valid Date1" });
      //   setdueDate("");
      //   setValues((val) => ({ ...val, dueDateYYYMMDD: "", dueDate: "" }));
      //   return null

      // }
      else {
        setdueDate(final);
        dispatch({
          type: UPDATE_FORMDATA,
          payload: {
            ...formValues,
            dueDateYYYMMDD: final,
            dueDate: final,
          },
        });
        setValues((val) => ({ ...val, dueDateYYYMMDD: final, dueDate: final }));
      }
    } else {
      setdueDate("");
      dispatch({
        type: UPDATE_FORMDATA,
        payload: {
          ...formValues,
          dueDateYYYMMDD: "",
          dueDate: "",
        },
      });
      setValues((val) => ({ ...val, dueDateYYYMMDD: "", dueDate: "" }));
    }
  };

  const handleChangeRawinvoice = (value) => {
    setInvoiceKeyPress("invoiceRawKey");
    let format = "";
    if (type == "dd/MM/yyyy") {
      format = "DD/MM/yyyy";
    } else {
      format = "MM/DD/yyyy";
    }

    if (value) {
      // if( type=="MM/dd/yyyy" ? ( VALID_DATE_MMDDYYYY_REGEX.test(value) == false || value.length != 10) : (VALID_DATE_DDMMYYYY_REGEX.test(value) == false || value.length != 10)){

      //  }
      const final = new Date(moment(value, format));
      const unmask = value.replace(/-|_/gi, "").replace(/\//g, "");
      let month = unmask.substring(0, 2);
      let date = unmask.substring(2, 4);
      if (
        month.length == 2 &&
        !(month >= 0 && month <= 12) &&
        final == "Invalid Date"
      ) {
        // Swal.fire(getAlertToast("Error", "Invalid Date"));
        CustomMessage("Invalid Date", "error", enqueueSnackbar); //call toster

        setErrors({ invoiceDate: "Allows Valid Date" });
      } else if (date.length == 2 && final == "Invalid Date") {
        // Swal.fire(getAlertToast("Error", "Invalid Date"));
        CustomMessage("Invalid Date", "error", enqueueSnackbar); //call toster

        setErrors({ invoiceDate: "Allows Valid Date1" });

        return null;
      } else {
        setStartDate(final);
        dispatch({
          type: UPDATE_FORMDATA,
          payload: {
            ...formValues,

            invoiceDate: final,
          },
        });
        setValues((val) => ({ ...val, invoiceDate: final }));
      }
    } else {
      setStartDate("");
      dispatch({
        type: UPDATE_FORMDATA,
        payload: {
          ...formValues,

          invoiceDate: "",
        },
      });
      setValues((val) => ({ ...val, invoiceDate: "" }));
    }
  };

  const handleAddPOnumber = async (number) => {
    Swal.fire({
      title: "Would you like to update all lines with this PO Number?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {             
        setisPoLineChange(1)
      }else{
        setisPoLineChange(0)
      }
  })
}


  const handleAddSuppier = async (name) => {
    Swal.fire({
      title: "Do you want to add supplier?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const config = {
          method: "POST",
          url: ADD_SUPPLIER_REQUEST_URL,
          headers: authHeader(),
          data: {
            // ...values,
            // teamId: getTeamID(),
            // requestedBy: getUserId(),
            webString: enCryptFun(
              JSON.stringify({
                supplierName: name,
                teamId: getTeamID(),
                inviteToPortal: false,
                requestedBy: getUserId(),
              })
            ),
            flutterString: "",
          },
        };
        try {
          const response = await API(config);
          //const { status, message } = response.data;
          let l = deCryptFun(response.data);

          const { status, message } = JSON.parse(l);
          if (status === "Success") {
            CustomMessage(
              "Supplier added",
              "success",
              enqueueSnackbar
            );
            getEmailList();
          }
        } catch (error) {
        } finally {
        }
      } else {
        dispatch({
          type: UPDATE_FORMDATA,
          payload: {
            ...formValues,
            name: name,
          },
        });
      }
    });
  };

  const handleNameChange = async (val) => {
    let nameinput = "";
    if (typeof val === "string") {
      nameinput = val;
      handleAddSuppier(nameinput);
    } else if (val && val.supplierName) {
      nameinput = val.supplierName;
      dispatch({
        type: UPDATE_FORMDATA,
        payload: {
          ...formValues,
          name: nameinput,
        },
      });

      setValues((v) => ({ ...v, name: nameinput }));

      Swal.fire({
        title: "Would you like to change the supplier?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          newSupplierData(nameinput, invoiceId);
        } else {
          setValues((v) => ({ ...v, name: name }));
          setValue(name);
          // setFromdata((v) => ({ ...v, name: name }));

          dispatch({
            type: UPDATE_FORMDATA,
            payload: {
              ...formValues,

              name: name,
            },
          });
        }
      });
    } else {
      nameinput = val;
    }
  };
  const handleChangeFun = (e) => {
    let val = e.target.value;
    let label = e.currentTarget.dataset.idlabel;
    let tempstatic = dynamicVal;
    tempstatic.find((element) => element.label == label).value = val;
    // setStaticSample(tempstatic);
    setDynamicVal(tempstatic);
    handleChange(e);
  };

  let ob = [];



  const [age, setAge] = React.useState("");

  const shandleChange = (event) => {
    setAge(event.target.value);
  };

  let recordFieldName = (name) => {
    if (name == "Vendor/Supplier Number") {
      return "Supplier Phone";
    } else if (name == "Invoice Description") {
      return "Comments";
    } else if (name == "Vendor/Supplier Site") {
      return "Supplier Site";
    } else if (name == "Vendor/Supplier Addresss") {
      return "Supplier Address";
    } else if (name == "Vendor/Supplier Bank Account") {
      return "Supplier Bank Account";
    } else if (name == "Vendor/Supplier Tax Number") {
      return "Supplier Tax Number";
    } else if (name == "Invoice Amount") {
      return "Sub Total";
    } else if (name == "Due Amount") {
      return "Invoice Total";
    } else {
      return name;
    }
  };
  const onAddND = (event) => {
    if (
      /^-?[0-9]\d*(\.\d+)?$/.test(event.target.value) || event.target.value.replace(/^-\d+/, "")|| event.target.value == ""
    ) {
      handleChange(event);
    }
    if (errors["dueAmount"]) {
      handleChange(event);
    }
  };
  const onAddNDAmount = (event) => {
    if (
      /^-?[0-9]\d*(\.\d+)?$/.test(event.target.value) || event.target.value.replace(/^-\d+/, "")|| event.target.value == ""
    ) {
      handleChange(event);
    }

    if (errors["invoiceAmount"]) {
      handleChange(event);
    }
  };
  const onAddNDTax = (event) => {
    if (
      /^-?[0-9]\d*(\.\d+)?$/.test(event.target.value) || event.target.value.replace(/^-\d+/, "")|| event.target.value == ""
    ) {
      handleChange(event);
    }

    if (errors["taxTotal"]) {
      handleChange(event);
    }
  };
  const onAddNDPaid = (event) => {
    if (
      /^-?[0-9]\d*(\.\d+)?$/.test(event.target.value) || event.target.value.replace(/^-\d+/, "")|| event.target.value == ""
    ) {
      handleChange(event);
    }

    if (errors["paidAmount"]) {
      handleChange(event);
    }
  };
  const onAddL = (event) => {
    if (LETTERS.test(event.target.value) || event.target.value == "") {
      handleChange(event);
    }
    if (errors["invoiceCurrency"]) {
      handleChange(event);
    }
  };
  const onAddLType = (event) => {
    if (LETTERS.test(event.target.value) || event.target.value == "") {
      handleChange(event);
    }

    if (errors["documentType"]) {
      handleChange(event);
    }
  };
  const onAddLS = (event) => {
    if (
      LETTERS_NUMBERS_SYMBOLS.test(event.target.value) ||
      event.target.value == ""
    ) {
      handleChange(event);
    }
    if (errors["orderNumber"]) {
      handleChange(event);
    }
  };
  const onchnagefunc = {
    invoiceAmount: onAddNDAmount,
    taxTotal: onAddNDTax,
    dueAmount: onAddND,
    invoiceCurrency: onAddL,
    documentType: onAddLType,
    paidAmount: onAddNDPaid,
    orderNumber: onAddLS,
  };

  const filter = createFilterOptions();

  const [popupAnchor, setPopupAnchor] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const openPopup = (event) => {
    const value = popupAnchor ? null : event.currentTarget
    setPopupAnchor(value);
    setIsPopupOpen(!!value);
  };

  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    const innerFunc = async () => {
      setTeamMembers(await getTeamMembers());
    }
    innerFunc();
  }, [])

  return (
    <Fragment>
      <Grid>
        <Grid xs={12} md={12}>
          <TableContainer
            sx={{ borderTopLeftRadius: "15px", borderTopRightRadius: "15px" }}
            component={Paper}
          >
            <Table sx={{}} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Field Name</StyledTableCell>
                  <StyledTableCell style={{ minWidth: "170px" }}>
                  Extracted Fields
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ textAlign: "left", position: "relative" }}
                  >
                    Extracted Values
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loadingdetect && <Spinner color="primary" />}
                {!loadingdetect &&
                  stableSort(
                    requiredFieldList.filter((i) => i.moduleName == "Invoice"),
                    getSorting("asc", "fieldOrder")
                  ).map((record, index) => {
                    const { columnName, dataFormat } = record;
                    const findingc = rawsec.find(
                      (element) => element.name === record.fieldName
                    );
                    let nts = "";
                    let foundval = "";

                    let dyfou =
                      dynamicVal &&
                      dynamicVal.find(
                        (element) =>
                          element.label == record.fieldName.toUpperCase()
                      );
                    let dyfoundval = "";
                    if (dyfou) {
                      dyfoundval = dynamicVal.find(
                        (element) =>
                          element.label == record.fieldName.toUpperCase().value
                      );
                    }
                    if (findingc) {
                      nts = rawsec.find(
                        (element) => element.name === record.fieldName
                      ).value;
                      if (
                        dynamicVal.find((element) => element.label == nts) !=
                        undefined
                      ) {
                        foundval = dynamicVal.find(
                          (element) => element.label == nts
                        ).value;
                      }
                    }

                    //option list
                    let simtre1 = dynamicVal.filter(
                      (idla1) => idla1.label !== record.name
                    );

                    return (
                      <>
                        {[
                          "dueDate",
                          "invoiceDate",
                          "discountDate",
                          "paidAmount",
                          "orderNumber",
                          "name",
                        ].includes(columnName) && record.isVisible == 1 ? (
                          columnName == "orderNumber" ? (
                            defaultInvoiceType == "po" ? (
                              <>
                                <StyledTableRow>
                                  <StyledTableCell
                                    className="po-number"
                                    sx={{
                                      color: " #a9abc3",
                                      fontSize: "15px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    PO Number
                                    {requiredFieldList.filter(
                                      (element) =>
                                        element.columnName === "orderNumber" &&
                                        element.isRequired === 1
                                    ).length == 1
                                      ? "*"
                                      : ""}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    <FormControl variant="standard" fullWidth>
                                      <Select
                                        sx={{ fontSize: "14px" }}
                                        name={record.fieldName}
                                        disabled={!isEdit}
                                        value={
                                          Object.keys(selectedOption).length &&
                                          Object.keys(selectedOption).includes(
                                            record.fieldName
                                          )
                                            ? selectedOption[
                                                record.fieldName
                                              ] || " "
                                            : detectedData.length &&
                                              detectedData[record.columnName]
                                            ? detectedData[record.columnName]
                                                .label
                                            : // detectedData[record.columnName].label.split(
                                              //     ":"
                                              //   )[0]

                                              ""
                                        }
                                        SelectDisplayProps={{
                                          "aria-label": "Without label",
                                          "data-labname": record.fieldName,
                                          "data-exaval": record.columnName,
                                          "data-filedid": record.fieldName,
                                        }}
                                        labname={record.fieldName}
                                        exaval={record.columnName}
                                        filedid={record.fieldName}
                                        onChange={RowHandlerChk}
                                        inputProps={{
                                          "aria-label": "Without label",
                                          "data-labname": record.fieldName,
                                          "data-exaval": record.columnName,
                                          "data-filedid": record.fieldName,
                                        }}
                                        onBlur={e => {                                       
                                          handleAddPOnumber(e.currentTarget.value);                                        
                                      }}     
                                      >
                                        <MenuItem
                                          value=""
                                          labname={record.fieldName}
                                          exaval={record.columnName}
                                          filedid={record.fieldName}
                                        >
                                          Select
                                        </MenuItem>
                                        {simtre1.map((i) => {
                                          return (
                                            <MenuItem
                                              style={{
                                                display:
                                                  Object.keys(selectedOption)
                                                    .length &&
                                                  Object.values(
                                                    selectedOption
                                                  ).includes(i.label)
                                                    ? "none"
                                                    : "inherit",
                                              }}
                                              key={`po_${i.label}`}
                                              value={i.label}
                                              labname={record.fieldName}
                                              exaval={record.columnName}
                                              filedid={record.fieldName}
                                              disabled={
                                                Object.keys(selectedOption)
                                                  .length &&
                                                Object.values(
                                                  selectedOption
                                                ).includes(i.label)
                                              }
                                            >
                                              {i.label}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    <PotextField
                                      sx={{ border: "none", fontSize: "14px" }}
                                      name="orderNumber"
                                      className={orderNumberTR}
                                      label="new"
                                      isEdit={isEdit}
                                      value={orderNumber}
                                      handleChange={
                                        onchnagefunc[record.columnName]
                                      }
                                      // handleChange={handleChange}
                                      touched={touched}
                                      errors={errors}
                                      idlabel="PO NUMBER"
                                      placeholder="Enter PO Number"
                                      variant="standard"
                                    //  handleBlur={handleBlur}
                                      handleBlur={e => {
                                        // call the built-in handleBur
                                        handleBlur(e)
                                        handleAddPOnumber(e.currentTarget.value);                                        
                                    }}
                                    />
                                  </StyledTableCell>
                                </StyledTableRow>
                              </>
                            ) : (
                              ""
                            )
                          ) : columnName == "paidAmount" ? (
                            <StyledTableRow>
                              <StyledTableCell
                                sx={{
                                  color: " #a9abc3",
                                  fontSize: "15px",
                                  fontWeight: "500",
                                }}
                              >
                                Paid Amount
                                {requiredFieldList.filter(
                                  (element) =>
                                    element.columnName === "paidAmount" &&
                                    element.isRequired === 1
                                ).length == 1
                                  ? "*"
                                  : ""}
                              </StyledTableCell>

                              <StyledTableCell></StyledTableCell>
                              <StyledTableCell align="center">
                                <PotextField
                                  //  sx={{ border: "none", fontSize: "14px"}}
                                  name="paidAmount"
                                  className={"invoicedatatext " + orderNumberTR}
                                  isEdit={isEdit}
                                  value={paidAmount}
                                  handleChange={onchnagefunc[columnName]}
                                  // handleChange={handleChange}
                                  touched={touched}
                                  errors={errors}
                                  idlabel={record.fieldName}
                                  placeholder="Enter Paid Amount"
                                  variant="standard"
                                  id="standard-error-helper-text"
                                  handleBlur={handleBlur}
                                />
                              </StyledTableCell>
                            </StyledTableRow>
                          ) : columnName == "name" ? (
                            getRole() != "Supplier" ? (
                              <StyledTableRow>
                                <StyledTableCell
                                  sx={{
                                    color: " #a9abc3",
                                    fontSize: "15px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Supplier Name
                                  {requiredFieldList.filter(
                                    (element) =>
                                      element.columnName === "name" &&
                                      element.isRequired === 1
                                  ).length == 1
                                    ? "*"
                                    : ""}
                                </StyledTableCell>
                                <StyledTableCell></StyledTableCell>

                                <StyledTableCell
                                  align="center"
                                  // style={{width:"160px"}}
                                >
                               <Autocomplete
                                    className="suppliername"
                                    disablePortal
                                    disabled={!isEdit}
                                    //onChange={handleNameChange}

                                    onChange={(event, newValue) => {
                                      if (typeof newValue === "string") {
                                        setValue({
                                          supplierName: newValue,
                                        });
                                        handleNameChange(newValue);
                                      } else if (
                                        newValue &&
                                        newValue.inputValue
                                      ) {
                                        // Create a new value from the user input
                                        setValue({
                                          supplierName: newValue.inputValue,
                                        });
                                        handleNameChange(newValue.inputValue);
                                      } else {
                                        setValue(newValue);
                                        handleNameChange(newValue);
                                      }
                                    }}
                                    value={name || value}
                                    name="name"
                                    id="name"
                                    SelectDisplayProps={{
                                      "aria-label": "Without label",
                                      "data-labname": record.fieldName,
                                      "data-filedid": record.fieldName,

                                      "data-exaval": record.columnName,
                                    }}
                                    labname={record.fieldName}
                                    exaval={record.columnName}
                                    filedid={record.fieldName}
                                    placeholder="Vendor/SupplierName"
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                    disableClearable
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    //onBlur={handleNameChange}
                                    options={supplierList}
                                    getOptionLabel={(option) => {
                                      // Value selected with enter, right from the input
                                      if (typeof option === "string") {
                                        return option;
                                      }
                                      // Add "xxx" option created dynamically
                                      if (option.inputValue) {
                                        return option.inputValue;
                                      }
                                      // Regular option
                                      return option.supplierName;
                                    }}
                                    freeSolo
                                    renderOption={(props, option) => (
                                      <li {...props}>{option.supplierName}</li>
                                    )}
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                    filterOptions={(options, params) => {
                                      const filtered = filter(options, params);

                                      const { inputValue } = params;
                                      // Suggest the creation of a new value
                                      const isExisting = options.some(
                                        (option) =>
                                          inputValue === option.supplierName
                                      );
                                      if (inputValue !== "" && !isExisting) {
                                        filtered.push({
                                          inputValue,
                                          supplierName: `Add "${inputValue}"`,
                                        });
                                      }

                                      return filtered;
                                    }}
                                  />
                                  <FormControl variant="standard" fullWidth>
                                    {/* <Select
                                      sx={{ fontSize: "14px" }}
                                      type="select"
                                      invalid={
                                        errors.name && touched.name
                                          ? true
                                          : false
                                      }
                                      //onBlur={handleBlur}
                                      disabled={!isEdit}
                                      onChange={handleNameChange}
                                      value={name || ""}
                                      name="name"
                                      id="name"
                                      SelectDisplayProps={{
                                        "aria-label": "Without label",
                                        "data-labname": record.fieldName,
                                        "data-filedid": record.fieldName,

                                        "data-exaval": record.columnName,
                                      }}
                                      labname={record.fieldName}
                                      exaval={record.columnName}
                                      filedid={record.fieldName}
                                      placeholder="Vendor/SupplierName"
                                      inputProps={{
                                        "aria-label": "Without label",
                                      }}
                                      disableUnderline
                                    >
                                      {supplierList.map((record, index) => {
                                        return (
                                          <MenuItem
                                            data-V="JHKJ"
                                            key={`listing_${index}`}
                                            value={record.supplierName}
                                            filedid={record.fieldName}
                                          >
                                            {record.supplierName}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select> */}
                                    <FormHelperText style={{ border: "none" }}>
                                      {touched.name && errors.name
                                        ? errors.name
                                        : ""}
                                    </FormHelperText>
                                  </FormControl>
                                </StyledTableCell>
                              </StyledTableRow>
                            ) : (
                              <StyledTableRow>
                                <StyledTableCell
                                  sx={{
                                    color: " #a9abc3",
                                    fontSize: "15px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Supplier Name
                                  {requiredFieldList.filter(
                                    (element) =>
                                      element.columnName === "name" &&
                                      element.isRequired === 1
                                  ).length == 1
                                    ? "*"
                                    : ""}
                                </StyledTableCell>

                                <StyledTableCell align="center">
                                  <PotextField
                                    sx={{ border: "none", fontSize: "14px" }}
                                    name="name"
                                    className={
                                      "invoicedatatext" + orderNumberTR
                                    }
                                    isEdit={isEdit}
                                    value={orderNumber}
                                    handleChange={handleChange}
                                    touched={touched}
                                    errors={errors}
                                    idlabel={record.fieldName}
                                    placeholder="Enter Supplier Name"
                                    id="standard-error-helper-text"
                                    handleBlur={handleBlur}
                                  />
                                </StyledTableCell>
                              </StyledTableRow>
                            )
                          ) : (
                            <StyledTableRow>
                              <StyledTableCell
                                sx={{
                                  color: " #a9abc3",
                                  fontSize: "15px",
                                  fontWeight: "500",
                                }}
                              >
                                {columnName == "dueDate" && "Due Date"}
                                {columnName == "invoiceDate" && "Invoice Date"}
                                {columnName == "discountDate" && "Discount Date"}

                                {columnName == "dueDate"
                                  && requiredFieldList.filter(
                                      (element) =>
                                        element.columnName === "dueDate" &&
                                        element.isRequired === 1
                                    ).length == 1
                                    ? "*"
                                    : ""
                                }
                                {columnName == "invoiceDate"
                                  && requiredFieldList.filter(
                                      (element) =>
                                        element.columnName === "invoiceDate" &&
                                        element.isRequired === 1
                                    ).length == 1
                                    ? "*"
                                    : ""
                                }
                                {columnName == "discountDate"
                                  && requiredFieldList.filter(
                                      (element) =>
                                        element.columnName === "discountDate" &&
                                        element.isRequired === 1
                                    ).length == 1
                                    ? "*"
                                    : ""
                                }
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                <PoSelect
                                  sx={{ fontSize: "14px" }}
                                  record={record}
                                  isEdit={isEdit}
                                  RowHandlerChk={RowHandlerChk}
                                  detectedData={detectedData}
                                  exaval={columnName}
                                  selectedOption={selectedOption}
                                  ArrayValue={simtre1}
                                />
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                <>
                                  <DateField
                                    columnName={columnName}
                                    isEdit={isEdit}
                                    errors={errors}
                                    dueDateTR={dueDateTR}
                                    invoiceDateTR={invoiceDateTR}
                                    touched={touched}
                                    idlabel={record.fieldName}
                                    changedDatedue={changedDatedue}
                                    changedDate={changedDate}
                                    changedDatediscount={changedDatediscount}
                                    invoiceDate={invoiceDate}
                                    discountDate={discountDate}
                                    dueDate={dueDate}
                                    type={type}
                                    placeholder="test"
                                    setDueDateError={setDueDateError}
                                    setInvoiceDateError={setInvoiceDateError}
                                    setDiscountDateError={setDiscountDateError}
                                  />

                                  {columnName == "dueDate" ? (
                                    dueDateTR == "Manual" ? (
                                      <div>
                                        {/* <hr
                                          style={{
                                            border: "0",
                                            borderTop:
                                              " 1px solid rgb(220 62 81)",
                                          }}
                                        /> */}
                                      </div>
                                    ) : (
                                      <div>
                                        {/* <hr
                                          style={{
                                            border: "0",
                                            borderTop:
                                              " 1px solid rgb(57 193 136)",
                                          }}
                                        /> */}
                                      </div>
                                    )
                                  ) : invoiceDateTR == "Manual" ? (
                                    <div>
                                      {/* <hr
                                        style={{
                                          border: "0",
                                          borderTop:
                                            " 1px solid rgb(220 62 81)",
                                        }}
                                      /> */}
                                    </div>
                                  ) : (
                                    <div>
                                      {/* <hr
                                        style={{
                                          border: "0",
                                          borderTop:
                                            " 1px solid rgb(57 193 136)",
                                        }}
                                      /> */}
                                    </div>
                                  )}
                                </>
                                <FormHelperText
                                  style={{ display: "block", color: "#d32f2f" }}
                                >
                                  {dueDateError && columnName == "dueDate"
                                    ? "Invalid date"
                                    : invoiceDateError &&
                                      columnName == "invoiceDate"
                                    ? "Invalid Date"
                                    : discountDateError && columnName == "discountDate"
                                    ? "Invalid Date"
                                    : columnName == "dueDate"
                                    ? errors.dueDate
                                    : columnName == 'invoiceDate'
                                    ? errors.invoiceDate
                                    : errors.discountDate}
                                </FormHelperText>
                              </StyledTableCell>
                            </StyledTableRow>
                          )
                        ) : // ""
                        columnName == "senderEmail" ||
                          columnName == "supplierId" ||
                          // columnName == "supplierSite" ||
                          columnName == "supplierAddress" ||
                          columnName == "supplierAddress2" ||
                          columnName == "city" ||
                          columnName == "state" ||
                          columnName == "country" ||
                          columnName == "postalCode" ||
                          columnName == "taxNumber" ||
                          columnName == "bankAccount" ||
                          columnName == "swift" ||
                          columnName == "sortCode" ||
                          columnName == "iban" ? (
                          !isEdit ? (
                            requiredFieldList.filter(
                              (element) =>
                                element.columnName === record.columnName &&
                                element.isVisible === 1
                            ).length == 1 ? (
                              <StyledTableRow>
                                <StyledTableCell
                                  sx={{
                                    color: " #a9abc3",
                                    fontSize: "15px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {recordFieldName(record.fieldName)}
                                  {requiredFieldList.filter(
                                    (element) =>
                                      element.columnName ===
                                        record.columnName &&
                                      element.isRequired === 1
                                  ).length == 1
                                    ? "*"
                                    : ""}
                                </StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell align="center">
                                  <PotextField
                                    // sx={{ border: "none", fontSize: "14px" }}
                                    name={columnName}
                                    className={formValues[`${columnName}TR`]}
                                    isEdit={isEdit}
                                    value={formValues[columnName]}
                                    handleChange={handleChange}
                                    touched={touched}
                                    errors={errors}
                                    idlabel={record.fieldName}
                                    placeholder={`Enter ${columnName}`}
                                    variant="standard"
                                    id="standard-error-helper-text"
                                    handleBlur={handleBlur}
                                  />
                                </StyledTableCell>
                              </StyledTableRow>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )
                        ) : requiredFieldList.filter(
                            (element) =>
                              element.columnName === record.columnName &&
                              element.isVisible === 1
                          ).length == 1 ? (
                          <StyledTableRow>
                            <StyledTableCell
                              sx={{
                                color: " #a9abc3",
                                fontSize: "15px",
                                fontWeight: "500",
                              }}
                            >
                              {recordFieldName(record.fieldName)}
                              {requiredFieldList.filter(
                                (element) =>
                                  element.columnName === record.columnName &&
                                  element.isRequired === 1
                              ).length == 1
                                ? "*"
                                : ""}
                              {/*TODO: replace with additional approve fields*/}
                              {record.fieldName === 'Mail Stop' && (
                                <>
                                  <Button
                                    // TODO: move to styles
                                    sx={{
                                      margin: "0px 8px !important",
                                      fontWeight: "bold !important",
                                      fontSize: "13px !important",
                                      color: "#3779EC !important",
                                      border: "1px solid #3779EC !important",
                                      textTransform: "none !important",
                                    }}
                                    variant="outlined"
                                    onClick={openPopup}
                                  >
                                    View Approver
                                  </Button>
                                  <Popper open={isPopupOpen} anchorEl={popupAnchor} placement={'bottom-start'}>
                                    <PopupBody>
                                      Approver Details:<br/>
                                      {teamMembers.filter(item => item.position.toLowerCase() === `Mail Stop Approver ${values['mailStop']}`.toLowerCase()).length ?
                                        teamMembers.filter(item => item.position.toLowerCase() === `Mail Stop Approver ${values['mailStop']}`.toLowerCase()).map(
                                          item => <>
                                            Name: {item['firstName']} {item['lastName']}<br/>
                                            Email: {item['email']}<br/>
                                            Role: {item['position']}<br/>
                                            Coding Segments: <br/>
                                            1001-2016-12200-57700-0000-0000-00000000
                                          </>
                                        ) :
                                        `No Approver found for Mail Stop ${values['mailStop'] ? values['mailStop'] : ''}`
                                      }
                                    </PopupBody>
                                  </Popper>
                                </>
                              )}
                            </StyledTableCell>

                            {record.fieldName == "Invoice Description" ||
                            record.fieldName == "Document Type" ||
                            record.fieldName == "Vendor/Supplier Name" ||
                            record.fieldName == "Vendor/Supplier Number" ||
                            record.fieldName == "Vendor/Supplier Site" ||
                            record.fieldName == "Invoice Currency" ||
                            record.fieldName == "Freight" ||
                            record.fieldName == "Paid Amount" ? (
                              <StyledTableCell></StyledTableCell>
                            ) : (
                              <StyledTableCell align="center">
                                <PoSelect
                                  sx={{ fontSize: "14px" }}
                                  record={record}
                                  isEdit={isEdit}
                                  RowHandlerChk={RowHandlerChk}
                                  detectedData={detectedData}
                                  exaval={columnName}
                                  selectedOption={selectedOption}
                                  ArrayValue={simtre1}
                                />
                              </StyledTableCell>
                            )}

                            <StyledTableCell align="center">
                            {columnName == "invoiceCurrency" ? (
                                <Autocomplete
                                  className="suppliername"
                                  disablePortal
                                  disabled={!isEdit}
            
                                  onChange={(event, newValue) => {
                                   console.log(values[columnName],"values[columnName]"); 
                                    if (typeof newValue === "string") {
                                      console.log(newValue,"99999");
                                      setValues((val)=>({...val,
                                        invoiceCurrency: newValue,
                                      }));
                                  
                                    } else if (
                                      newValue &&
                                      newValue.inputValue
                                    ) {
                                      // Create a new value from the user input
                                      setValues({
                                        invoiceCurrency: newValue.inputValue
                                      });
                                    } else {
                                      setValue(newValue);
                                    }
                                  }}
                                  value={values[columnName]?values[columnName]:""}
                                  handleChange={onchnagefunc[columnName]}
                                  name="invoiceCurrency"
                                  id="invoiceCurrency"
                                  disableClearable
                                  selectOnFocus
                                  clearOnBlur
                                  handleHomeEndKeys
                                  options={commonCurrency}
                                  getOptionLabel={(option) => {
                                    if (typeof option === "string") {
                                      return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.inputValue) {
                                      return option.inputValue;
                                    }
                                    // Regular option
                                    return option;
                                  }}
                                  freeSolo
                                  renderOption={(props, option) => (
                                    <li {...props}>{option}</li>
                                  )}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                  filterOptions={(options, params) => {
                                    const filtered = filter(options, params)                                 
                                    return filtered;
                                  }}
                                />
                              ) : (
                              <PotextField
                                // sx={{ border: "none", fontSize: "14px" }}
                                name={columnName}
                                className={formValues[`${columnName}TR`]}
                                isEdit={isEdit}
                                value={values[columnName]}
                                handleChange={
                                  record.fieldName == "Invoice Amount" ||
                                  columnName == "taxTotal" ||
                                  columnName == "dueAmount" ||
                                  columnName == "invoiceCurrency" ||
                                  columnName == "documentType"
                                    ? onchnagefunc[columnName]
                                    : handleChange
                                }
                                // handleChange={handleChange}
                                touched={touched}
                                errors={errors}
                                idlabel={record.fieldName}
                                placeholder={`Enter ${recordFieldName(
                                  record.fieldName
                                )}`}
                                variant="standard"
                                id="standard-error-helper-text"
                                handleBlur={handleBlur}
                              />
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        ) : (
                          ""
                        )}
                      </>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Fragment>
  );
}
export default React.memo(InvoiceFormNewExtract);

function PotextFieldMemo(props) {
  const {
    className,
    isEdit,
    value,
    handleChange,
    touched,
    errors,
    name,
    idlabel,
    placeholder,
    handleBlur,
  } = props;
  const newClassname = useMemo(() => {
    return touched[name] && errors[name] ? "" : "invoicedatatext";
  }, [touched, errors, name]);

  const newlabel = useMemo(() => {
    return className === "Extract" ? "Extracted" : "Manual";
  }, [className]);

  const newColor = useMemo(() => {
    return className === "Extract" ? "success" : "secondary";
  }, [className]);

  // const newHandleChange = useCallback(
  //   (e) => {
  //     handleChange(e);
  //   },
  //   [handleChange]
  // );

  let lablenew = (className, name) => {
    if (name == "documentType" || name == "invoiceCurrency") {
      return "Default";
    } else if (name == "invoiceDescription") {
      return "Manual";
    }
    // else if (name == "orderNumber") {
    //   return "Extracted";
    // }
    else {
      return className === "Extract" ? "Extracted" : "Manual";
    }
  };

  return (
    <>
      {/* <FormControl
        className={className === "Extract" ? "extract" : "manual"}
        sx={{ m: 1, minWidth: 120 }}
      > */}
      {/* <TextField type={"text"} /> */}

      <TextField
        // id="outlined-disabled"
        disabled={!isEdit}
        className={errors[name] ? "" : "invoicedatatext"}
        label={lablenew(className, name)}
        color={className === "Extract" ? "success" : "secondary"}
        focused
        // color="secondary"
        // outline="none"
        // sx={{ border: "none", fontSize: "14px" }}
        multiline={["invoiceDescription","supplierSite"].includes(name)? true :false}
         
        maxRows={4}
        type="text"
        error={errors[name]}
        name={name}
        // value={value || ""}
        value={value != "NaN" ? value || "" : ""}
        data-idlabel={idlabel}
        onChange={handleChange}
        onBlur={handleBlur}
        // invalid={touched[name] && errors[name] ? true : false}
        placeholder={placeholder}
        inputProps={{
          style: { WebkitBoxShadow: "0 0 0 1000px white inset" },
        }}
      />
      <FormHelperText id="standard-error-helper-text" error>
        {/* {touched[name] && errors[name] ? errors[name] : ""} */}
        {isEdit && errors[name] ? errors[name] : ""}
      </FormHelperText>
      {/* </FormControl> */}
    </>
  );
}

const PotextField = React.memo(PotextFieldMemo);

function PoSelectMemo(props) {
  const {
    record,
    isEdit,
    RowHandlerChk,
    detectedData,
    exaval,
    selectedOption,
    ArrayValue,
  } = props;

  return (
    <>
      <FormControl variant="standard" fullWidth>
        <Select
          sx={{ fontSize: "14px" }}
          name={record.fieldName}
          disabled={
            !isEdit ||
            record.fieldName == "Invoice Description" ||
            record.fieldName == "Document Type" ||
            record.fieldName == "Vendor/Supplier Name" ||
            record.fieldName == "Vendor/Supplier Number" ||
            record.fieldName == "Invoice Currency" ||
            record.fieldName == "Freight" ||
            record.fieldName == "Paid Amount"
          }
          labname={record.fieldName}
          exaval={exaval}
          filedid={record.fieldName}
          onChange={RowHandlerChk}
          value={
            Object.keys(selectedOption).length &&
            Object.keys(selectedOption).includes(record.fieldName)
              ? selectedOption[record.fieldName] || " "
              : detectedData && detectedData[record.columnName]
              ? detectedData[record.columnName].label
              : // detectedData[record.columnName].label.split(
                //     ":"
                //   )[0]
                ""
          }
          SelectDisplayProps={{
            "aria-label": "Without label",
            "data-labname": record.fieldName,
            "data-exaval": record.columnName,
            "data-filedid": record.fieldName,
          }}
          inputProps={{
            "aria-label": "Without label",
            "data-labname": record.fieldName,
            "data-exaval": exaval,
            "data-filedid": record.fieldName,
          }}
          disableUnderline
        >
          <MenuItem
            value=""
            exaval={exaval}
            labname={record.fieldName}
            filedid={record.fieldName}
          >
            Select
          </MenuItem>

          {ArrayValue.map((i) => {
            return (
              <MenuItem
                style={{
                  display:
                    Object.keys(selectedOption).length &&
                    Object.values(selectedOption).includes(i.label)
                      ? "none"
                      : "inherit",
                }}
                key={`col_${i.label}`}
                value={i.label}
                filedid={record.fieldName}
                labname={record.fieldName}
                exaval={exaval}
                disabled={
                  Object.keys(selectedOption).length &&
                  Object.values(selectedOption).includes(i.label)
                }
              >
                {i.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
}

const PoSelect = React.memo(PoSelectMemo);
function DateFieldMemo(props) {
  const {
    columnName,
    errors,
    touched,
    idlabel,
    changedDatedue,
    changedDate,
    changedDatediscount,
    dueDate,
    invoiceDate,
    discountDate,
    dueDateTR,
    invoiceDateTR,
    discountDateTR,
    isEdit,
    type,
    setDueDateError,
    setInvoiceDateError,
    setDiscountDateError,
    dueDateError,
    invoiceDateError,
  } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        name={columnName}
        inputFormat={type}
        variant="inline"
        allowSameDateSelection
        InputProps={{
          disableUnderline: true,
        }}
        // onError={(reason, value) => {
        // }}
        onError={(e) => {
          if (columnName == "dueDate") {
            if (e == "invalidDate" || e == "minDate" || e == "maxDate") {
              setDueDateError(true);
            } else {
              setDueDateError(false);
            }
          } else if (columnName == "invoiceDate") {
            if (e == "invalidDate" || e == "minDate" || e == "maxDate") {
              setInvoiceDateError(true);
            } else {
              setInvoiceDateError(false);
            }
          } else if (columnName == "discountDate") {
            if (e == "invalidDate" || e == "minDate" || e == "maxDate") {
              setDiscountDateError(true);
            } else {
              setDiscountDateError(false);
            }
          }
        }}
        className="invoiceddate"
        // className={
        //   columnName == "dueDate"
        //     ? // ? touched.dueDate &&
        //       errors.dueDate
        //       ? ""
        //       : "invoiceddate"
        //     : errors.invoiceDate
        //     ? ""
        //     : "invoiceddate"
        // }
        label={
          columnName == "dueDate"
            ? dueDateTR === "Extract"
              ? "Extracted"
              : "Manual"
          : columnName == "invoiceDate"
            ? invoiceDateTR === "Extract"
              ? "Extracted"
              : "Manual"
            : discountDateTR === "Extract"
              ? "Extracted"
              : "Manual"
        }
        // focused
        data-idlabel={idlabel}
        disabled={!isEdit}
        invalid={
          columnName == "dueDate"
            ? // ? touched.dueDate &&
              errors.dueDate
              ? true
              : false
          : columnName == "invoiceDate" ?
              errors.invoiceDate
              ? true
              : false
            : errors.discountDate
              ? true
              : false
        }
        value={columnName == "dueDate" ? dueDate || null : columnName == 'invoiceDate' ? invoiceDate || null : discountDate || null}
        onChange={
          columnName == "dueDate"
            ? (date) => changedDatedue(date)
            : columnName == "invoiceDate" ?
                (date) => changedDate(date)
              : (date) => changedDatediscount(date)
        }
        renderInput={(params) => (
          <TextField
            sx={{ fontSize: "14px" }}
            className="invoiceddate"
            focused
            onError={(e) => {
              return true;
            }}
            color={
              columnName == "dueDate"
                ? dueDateTR === "Extract"
                  ? "success"
                  : "secondary"
                : ""
            }
            // className={
            //   columnName == "dueDate"
            //     ? // ? touched.dueDate &&
            //       errors.dueDate
            //       ? "invoiceddate"
            //       : "invoiceddate"
            //     : errors.invoiceDate
            //     ? "invoiceddate"
            //     : ""
            // }
            {...params}
            inputProps={{
              ...params.inputProps,
              style: { WebkitBoxShadow: "0 0 0 1000px white inset" },
              placeholder:
                // columnName == "dueDate"
                // ?"Enter due date"
                // :"Enter invoice date",
                type === "MM/dd/yyyy" ? "MM/DD/YYYY" : "DD/MM/YYYY",
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
  
}

const DateField = React.memo(DateFieldMemo);
