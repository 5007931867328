import React, { Fragment, useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import { useFormik } from "formik";
import * as Yup from "yup";
import { GET_AUDIT_URL } from "../comman/url";
import { authHeader, getAlert } from "../comman/mainfunction";
import { deCryptFun, enCryptFun, getTeamID } from "../comman/function";
import API from "../constants/API";
import Swal from "sweetalert2";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { CustomMessage } from "../comman/helperfunction";
import "moment-timezone";
const useStyles = makeStyles((theme) => ({
  labelfont: {
    fontSize: "14px !important",
  },
}));
const DemoMemo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const Demo = React.memo(DemoMemo);

function AuditLog(props) {
  const { auditFlag, invoiceId, callBack, list } = props;
  const { enqueueSnackbar } = useSnackbar();

  const styleclasses = useStyles();
  useEffect(() => {
    //getAudit();
  }, []);

  return (
    <Demo>
      <List>
        {list && list.length ? (
          list.map((record, index) => {
            // var gmtDateTime = moment.utc(record.actionDate, "YYYY-MM-DD");
            // var convert12Date = gmtDateTime.local().format("YYYY-MM-DD");

            // let convert12Time = moment(record.actionDate, "HH:mm").format(
            //   "hh:mm A"
            // );
            // var localTime = moment.utc(record.actionDate).toDate();

            // var gmtDateTime = moment.utc(localTime, "YYYY-MM-DD");
            // var convert12Date = gmtDateTime.local().format("YYYY-MM-DD");

            // let convert12Time = moment(localTime, "HH:mm").format("hh:mm A");
            const localdata = new Date();
            const dateAsString = localdata.toString();
            const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];
            let timex=moment().tz(timezone).format()
            return (
              <ListItem
                secondaryAction={
                  <Typography align="right" className={styleclasses.labelfont}>
                      {/* {moment(record.actionDate).tz(timezone).format("hh:mm A, MM/DD/YYYY")} */}
                    {/* {convert12Time} */}
                    {moment.parseZone(record.actionDate).format("hh:mm A, MM/DD/YYYY")}
                  </Typography>
                }
                alignItems={"center"}
              >
                <ListItemAvatar>
                  <Avatar
                    alt={record.actionName}
                    src="/static/images/avatar/1.jpg"
                  />
                </ListItemAvatar>
                <ListItemText sx={{width: "240px"}} primary={record.actionName} />
                <ListItemText sx={{width: "100%", display: "flex", justifyContent: "center"}} primary={record.comment} />
              </ListItem>
            );
          })
        ) : (
          <ListItem>No audit found</ListItem>
        )}
      </List>
    </Demo>
  );
}
export default React.memo(AuditLog);
