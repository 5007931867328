import {GET_USERS, NOTIFY_APPROVER_URL, SAVE_AUDIT, SAVE_NOTIFICATION_DETAILS} from "./url";
import {authHeader} from "./mainfunction";
import {deCryptFun, enCryptFun, getTeamID, getUserId} from "./function";
import API from "../constants/API";
import {CustomMessage} from "./helperfunction";

export const sendNotification = (
  email,
  userId,
  invoiceId,
  enqueueSnackbar,
  email_sent_finally_callback,
  save_notification_finally_callback,
  save_audit_finally_callback
) => {
  const saveFormValues = async () => {
    const keycongigure = {
      method: "POST",
      url: NOTIFY_APPROVER_URL,
      headers: authHeader(),
      data: {
        webString: enCryptFun(
          JSON.stringify({
            email: email,
            hyperLink: window.location.href,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(keycongigure);

      CustomMessage("An email has been sent to the selected approver(s).", "success", enqueueSnackbar);
      saveNotification();
    } finally {
      email_sent_finally_callback();
    }
  };

  const saveNotification = async () => {
    const keycongigure = {
      method: "POST",
      url: SAVE_NOTIFICATION_DETAILS,
      headers: authHeader(),
      data: {
        webString: enCryptFun(
          JSON.stringify({
            notificationDetailsId: 0,
            userId: userId.toString(),
            tag: "Notify Approver",
            payload: "You have been assigned to approve this invoice",
            invoiceId: invoiceId,
            isRead: true,
            commentId: 0,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(keycongigure);
      saveAudit();
    } finally {
      save_notification_finally_callback()
    }
  };
  const saveAudit = async () => {
    const config = {
      method: "PUT",
      url: SAVE_AUDIT,
      headers: authHeader(),
      data: {
        webString: enCryptFun(
          JSON.stringify({
            invoiceId: invoiceId,
            comments: "Notify Approver",
            actionBy: getUserId(),
            isExceptionResolved: false,
          })
        ),
        flutterString: "",
      },
    };
    try {
      const response = await API(config);
      // let l = deCryptFun(response.data);
      // const {status, message} = JSON.parse(l);
    } finally {
      save_audit_finally_callback()
    }
  };

  saveFormValues();
}

const initialUsersFilterParams = {
  offset: 0,
  count: 1000, // TODO: change
};

export const getTeamMembers = async (filterparams = initialUsersFilterParams) => {
  const config = {
    method: "GET",
    url: GET_USERS,
    headers: authHeader(),
    params: {
      webString: enCryptFun(
        JSON.stringify({
          teamId: getTeamID(),
          // userRole: "Team Member",
          ...filterparams,
          email: filterparams.email?.replace("'", "\\'"),
          firstName: filterparams.firstName?.replace("'", "\\'"),
          lastName: filterparams.lastName?.replace("'", "\\'"),
        })
      ),
      flutterString: "",
    },
  };
  try {
    const response = await API(config);

    let l = deCryptFun(response.data);

    const {status, data, count} = JSON.parse(l);
    // const { status, data, count } = response.data;
    if (status === "Success") {
      return (
        data.map((item) => {
          return {
            ...item,
            position: item.position || "-",
          };
        })
      );
    }
  } catch (error) {
    // if (error.response) {
    //   let {data} = error.response;
    //   let decryptErr = deCryptFun(data);
    //   let parsedErr = JSON.parse(decryptErr);
    // }
  } finally {
    //  setLoading(false);
  }
}